<template>
	<div class="container-fluid animatedParent animateOnce my-3">
		<!-- main content -->
		<div class="tab-content">
			<div class="tab-pane animated fadeInUpShort show active go">
				<div class="row my-3">
					<div class="col-md-12">
						<div class="card r-0 shadow">
							<div class="card-header">

								<div class="row">
									<div class="col-md-12">
										<!-- 搜索 start -->
										<div class="form-inline mt-3">
											<input v-model="query" class="form-control p-2" placeholder="搜索" type="text">

											<!-- <date-picker class="ml-2" v-model="start_date" format="YYYY-MM-DD" value-type="format"
												input-class="form-control" :editable="false" :clearable="true" placeholder="有效期开始时间">
											</date-picker>
											<div class="ml-2">
												<date-picker v-model="end_date" format="YYYY-MM-DD" value-type="format"
													input-class="form-control" :editable="false" :clearable="true" placeholder="有效期结束时间">
												</date-picker>
											</div> -->
											
											<!-- <span>
												<select v-model="dept_id" class="form-control  ml-2">
													<option value="">所有企业</option>
													<option v-bind:key="index" 
														:value="item.id" v-for="(item,index) in departmentlist">
														{{item.department_name}}</option>
												</select>
											</span> -->
											
											<select class="form-control ml-2" v-model="category">
												<option value="">所有福利分类</option>
												<template v-for="(item,index) in categorylist">
												<option v-bind:key="index"
													:value="item.value">
													{{item.name}}</option>
												</template>
											</select>

											<!-- <span>
												<select v-model="stock_type" class="form-control  ml-2">
													<option value="">所有类型</option>
													<option v-bind:key="index" 
														:value="item.value" v-for="(item,index) in stocktypelist">
														{{item.name}}</option>
												</select>
											</span>

											<select v-model="coupon_state" class="form-control ml-2">
												<option value="">所有状态</option>
												<option v-bind:key="index"
													:value="item.value" v-for="(item,index) in couponstatelist">
													{{item.name}}</option>
											</select> -->
											
											<select v-model="del_flag" class="form-control ml-2">
												<option value="">所有回收状态</option>
												<option value="0">未回收</option>
												<option value="1">已回收</option>
											</select> 
												
											<button @click="search" type="button" class="btn btn-secondary  ml-2"><i
													class="icon-search"></i>
											</button>
											
											<!-- <div class="form-check ml-2">
												<label class="form-check-label">
													<input 
													v-model="del_flag" 
													class="form-check-input" type="checkbox"> 包括已回收
												</label>
											</div> -->
										</div>
										<!-- 搜索 end -->
									</div>
									<!-- <div class="col-md-3">
										<div class="float-right m-3">
											<button @click="add" type="button" class="btn btn-secondary">创建制度</button>
										</div>
									</div> -->
								</div>
							</div>

							<div class="table-responsive">
								<div v-if="userInstitutionList.length > 0">
									<table class="table table-striped table-hover r-0  mb-0">
										<thead>
											<tr class="no-b">
												<th style="width: 60px">
													<!-- <div class="custom-control custom-checkbox">
														<input v-model="check_all" @click.stop="checkAll" type="checkbox"  class="custom-control-input" id="checkedAll"><label class="custom-control-label" for="checkedAll"></label>
													</div> -->
													序号
												</th>
												<th>
													<div class="d-none d-lg-block">福利分类</div>
												</th>
												<th>
													<div class="d-none d-lg-block">制度类型</div>
												</th>
												<!-- <th>
													<div class="d-none d-lg-block">发放批次号</div>
												</th> -->
												<th>
													<div class="d-none d-lg-block">制度名称</div>
												</th>
												<th>
													<div class="d-none d-lg-block">员工</div>
												</th>
												<!-- <th>
													<div class="d-none d-lg-block">额度类型</div>
												</th> -->
												<th>
													<div class="d-none d-lg-block">额度(元)</div>
												</th>
												<th>
													<div class="d-none d-lg-block">状态</div>
												</th>
												<!-- <th>
													<div class="d-none d-lg-block">转赠</div>
												</th> -->
												<th>
													<div class="d-none d-lg-block">有效期</div>
												</th>
												<th>
													<div class="d-none d-lg-block">发放时间</div>
												</th>
												<!-- <th>
													<div class="d-none d-lg-block">备注</div>
												</th> -->
												<!-- <th>
													<div class="d-none d-lg-block">更新时间</div>
												</th> -->
												<th></th>
											</tr>
										</thead>

										<tbody>
											<tr v-bind:key="index" v-for="(item,index) in userInstitutionList"
												:class="{'blue lighten-5':selectedIndex == index}">
												<td>
													{{index+1}}
													<!-- <label class="custom-control custom-checkbox">
														<input v-model="checkedIds" :value="item.id" :id="index" :disabled="item.enabled == -1" class="custom-control-input" type="checkbox"><label class="custom-control-label" :for="index"></label>
													</label> -->
												</td>
												<td>
													<div class="d-none d-lg-block">
														<template v-if="categoryMap.hasOwnProperty(item.category)">
															{{categoryMap[item.category]}}
														</template>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														{{expenseTypeMap[item.expense_type]}}
													</div>
												</td>

												<!-- <td>
													<div class="d-none d-lg-block">{{item.quota_id}}</div>
												</td> -->
												
												<td>
													<div class="d-none d-lg-block">
													{{item.institution_name}}
													<br>
													<span class="text-muted s-12">{{item.issue_batch_id}}</span>
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
													{{item.employee_name}}
													<br>
													<span class="text-muted s-12">{{item.employee_mobile}}</span>
													</div>
												</td>
												
												<!-- <td>
													<div class="d-none d-lg-block">
														{{quotaTypeMap[item.quota_type]}}
													</div>
												</td> -->
												
												<td>
													<div class="d-none d-lg-block font-weight-bold">
														<!-- {{item.quota_available}}/ -->
														{{item.quota_total}}
														
														<!-- <button
														@click.stop="refresh(index)" type="button"
															class="btn btn-link btn-xs"> <i class="icon-refresh"></i> </button>
														 -->
													</div>
												</td>
												
												<td>
													<div class="d-none d-lg-block">
														<template v-if="item.del_flag == 1">
															<span class="text-danger">已回收</span>
														</template>
														<template v-else>
															<span :class="{'text-success':item.freeze==0,'text-danger':item.freeze==1}">{{item.freeze==0?'可用':'冻结'}}</span>
														</template>	
													</div>
												</td>

												<!-- <td>
													<div class="d-none d-lg-block">
														{{item.share_mode==1?'允许':'不允许'}} 
													</div>
												</td> -->

												
												<td>
													<div class="d-none d-lg-block">
													{{item.effective_start_date}}
													~
													{{item.effective_end_date=='2222-01-01' || item.effective_end_date=='2099-12-31'?'长期':item.effective_end_date}}
													</div>
												</td>
												
												
												<td>
													<div class="d-none d-lg-block">
													{{item.create_date}}
													</div>
												</td>
												
												
												
												<td>
													<div class="d-none d-lg-block">
													{{item.receive_time}}
													<br>
													{{item.use_time}}
													</div>
												</td>
												<td>
													<div class="d-none d-lg-block">
														<span :class="{'text-success':item.coupon_state=='SENDED','text-danger':item.coupon_state=='USED'}">
															{{item.stateDesc}}
															<template v-if="item.locked==1">(锁住)</template>
														</span>
													</div>
												</td>
												
												<!-- <td>
													<div class="d-none d-lg-block">{{item.comment}}</div>
												</td> -->
												
												<!-- <td>
													<div class="d-none d-lg-block">{{item.update_date}}</div>
												</td> -->
												
												<td class="form-inline pt-4">
													
													<!-- <button @click.stop="refresh(index)" type="button"
														:disabled="item.coupon_state == 'DEACTIVATED'"
														class="btn btn-outline-primary btn-xs"> 刷新(TEST) </button> -->
														<template v-if="!item.del_flag">
													<button
														@click.stop="deactivateReady(index)" type="button" 
														:disabled="item.coupon_state == 'DEACTIVATED'"	
														class="btn btn-outline-primary btn-xs ml-2"> 作废 </button>	
														</template>
														
													<!-- <button @click.stop="view(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-2"> 查看领券者 </button>
													 -->
													
													<!-- <button
														@click.stop="updateInfoReady(index)" type="button"
														class="btn btn-outline-primary btn-xs ml-2"> 修改资料 </button>
														 -->
													<!-- <div class="dropdown">
													  <button type="button" class="btn btn-link ml-2"  role="button" id="" data-toggle="dropdown" aria-expanded="false">
													   <i class="icon-more_horiz"></i>	
													  </button>
													  <div class="dropdown-menu" aria-labelledby="">
													    <span @click.stop="edit(index)" class="dropdown-item" >修改基本资料</span>
													    <span class="dropdown-item" >修改数量</span>
													  </div>
													</div> -->
													
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div v-if="userInstitutionList.length == 0">
									<div class="card-body text-lg-center m-5 height-300">
										<h5 class="mb-3">没有发放记录</h5>
										<!-- <button type="button" class="btn btn-lg btn-secondary">
											创建制度
										</button> -->
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="row" v-if="userInstitutionList.length > 0">
					<div class="col-sm-12 col-md-5">
						<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
							显示 {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
						</div>
					</div>
					<div class="col-sm-12 col-md-7">

						<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>

					</div>
				</div>

			</div>

		</div>
		<!-- //main content -->

		<!-- modal -->
		<!-- 详情 modal -->
		
		<!-- //详情 modal -->

		<!--  作废 modal -->
		<div class="modal fade" id="cModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">作废</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div role="alert" class="alert alert-warning"><strong>注意!</strong> 
						已使用过的补贴额度不可作废。
						</div>

						<div class="m-3">
							<div class="form-group">
								<label for="">制度名称</label>
								<input v-model="institution.institution_name" disabled type="text" class="form-control-plaintext"
									placeholder="输入制度名称">
								<div>{{institution.institution_id}}</div>	
								<div class="s-12">
									可用金额{{institution.quota_total}}元
								</div>	
							</div>
							<hr>

							<div class="form-group">
								<label for="">作废原因(选填)</label>
								<input v-model="institution.deactivate_reason" type="text" class="form-control-lg"
									placeholder="输入作废原因">
								<div class="s-12">
								</div>	
							</div>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						
						<button
						@click.stop="deactivate()" :disabled="disabled" 
						type="button" class="btn btn-info">  确定作废
						</button>
						
						
					</div>
				</div>
			</div>
		</div>
		<!-- //作废 modal -->

	</div>
</template>

<script>
	

	import VPagination from '../components/my-page.vue'
	// import DatePicker from 'vue2-datepicker';
	// import 'vue2-datepicker/index.css';
	// import 'vue2-datepicker/locale/zh-cn';
	
	export default {
		data() {
			return {
				title: ' 用户券列表',
				user_role: '',
				
				token: '',

				query: '',

				current_page: 1,
				page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,

				total: 0, // 记录总条数
				display: 10, // 每页显示条数
				current: 1, // 当前的页数

				selectedIndex: -1,
				institution: {}, //选中的对象
				count_type: 1,

				//////
				start_date: '',
				end_date: '',
				
				disabled: false,
				
				userInstitutionList: [],
				del_flag: '',
				
				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				expenseTypeMap: {
					'DEFAULT': '通用',
					'MEAL':'餐饮',
					'MALL':'商城',
					'METRO': '公交地铁',
					'CAR': '用车',
					'PREPAYRECHARGE': '话费充值',
					'HOTEL': '酒店',
					'TRAIN': '火车票',
					'PLANE': '机票',
				},
				subexpenseTypeMap: {
					'DEFAULT': '通用',
					'REACH_SHOP':'到店',
					'TAKE_AWAY':'外卖',
					'ONLINE_MALL':'线上商城',
					'METRO': '公交地铁',
					'CAR': '用车',
					'ONLINE_PREPAY_RECHARGE': '线上充值',
					'REACH_SHOP_HOTEL': '到店住宿',
					'ONLINE_HOTEL': '在线订酒店',
					'ONLINE_TRAIN': '火车票预订',
					'ONLINE_PLANE': '机票预订',
				},
				quotaTypeMap: {
					'CAP':'余额',
					'COUPON': '点券'
				},
				ppMap: {
					'COMBINATION': '超额部分由个人支付',
					'PERSONAL': '整单全部由个人支付'
				},
				
				//本地分类
				// categorylist: [
				// 	// {name: '选择分类', value: ''},
				// 	{name: '弹性福利', value: 'DEFAULT'},
				// 	{name: '餐食外卖', value: 'MEAL'},
				// 	{name: '交通出行', value: 'CAR'},
				// 	{name: '工会福利', value: 'COMMON'},//OTHER
				// ],
				categorylist: this.GLOBAL.categorylist,
				category: '',
				
				// categoryMap: {
				// 	"DEFAULT": "弹性福利",
				// 	"MEAL": "餐食外卖",
				// 	"CAR": "交通出行",
				// 	"COMMON": "工会福利",
				// },
				categoryMap: [],
				
			}

		},
		components: {
			VPagination,
			// DatePicker
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;
			
			//2.取得商户信息
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
			self.user_role = lsLoginData.user_role;
			
			//构造categoryMap
			self.categoryMap = [];
			for(let i=0; i<this.GLOBAL.categorylist.length; i++){
				let et = this.GLOBAL.categorylist[i];
				self.categoryMap[et.value] = et.name;
			}
		},

		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//先取预载数据，再取业务数据
			if(self.user_role == 'ADMIN'){
				self.init(() => {
					self.initData();
				})
			}
			else{
				self.initData();
			}
		},
		methods: {

			init(callback) {
			let self = this;
			    	
			//
			this.axios.get(this.GLOBAL.baseURI + 'getDepartmentList', {
					params: {
						token: self.token,
						// dept_id: self.dept_id,
						// query: self.query,
						// current_page: self.current_page,
						// page_size: self.page_size,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) {
						self.departmentlist = data.data.departments;
						
						for (let i = 0; i < self.departmentlist.length; i++) {
							let dept = self.departmentlist[i];
							self.deptmap[dept.id] = dept;
						}
						console.log('--- dept map ->', self.deptmap);
						
						// self.current_date = data.data.current_date;
						
						if (typeof callback == 'function') {
						    callback()
						}
					} 
					else if (data.code == 101) {
						//登录超时
						// location.href = "index.html";
						self.logoff();
					} 
					else {
						alert(data.message)
					}
				});
			//END
			},

			initData() {
				let self = this;
				
				self.loader = self.$loading.show();

				//调用接口
				self.axios.get(self.GLOBAL.baseURI + 'getUserInstitutionList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							category: self.category,
							query: self.query,
							// start_date: self.start_date,
							// end_date: self.end_date,
							current_page: self.current_page,
							page_size: self.page_size,
							del_flag: self.del_flag,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						
						self.loader.hide();
						
						console.log(data.data);
						if (data.code == 200) {

							self.userInstitutionList = data.data.list;
							
							// self.start_date = data.data.start_date;
							// self.end_date = data.data.end_date;

							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)

							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;

							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数

							self.userInstitutionList = []; //重置
							for (let institution of data.data.list) {
								// institution.typeDesc = __STOCKTYPE[institution.stock_type];
								// institution.stateDesc = institution.coupon_state?__STOCKSTATE[institution.coupon_state]:""
								// console.log('--- state ->',institution.stateDesc)
								self.userInstitutionList.push(institution);
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END

			},

			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initData();
			},

			search() {
				let self = this;
				self.current_page = 1;
				self.page_size = 50;
				self.initData();
			},
			
			//刷新状态
			refresh(index) {
				console.log(index);
				let self = this;
			
				let institution = self.userInstitutionList[index];
				console.log(index, institution);
			
				self.selectedIndex = index;
				
				// 组装数据
				let postData = {
					token: self.token,
					dept_id: self.dept_id,
					id: institution.id,
					institution_id: institution.institution_id,//备用 
					nonce_str: institution.nonce_str,
				}
				
				self.loader = self.$loading.show();
				
				self.axios.get(self.GLOBAL.baseURI + 'refreshUserInstitution', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						
						self.loader.hide();
				
						if (data.code == 200) {
							
							if(data.data.result_code == 'success'){
								self.$toasted.success('刷新成功', {
									position: 'top-center',
								}).goAway(2000)
								
								//刷新修改的字段
								let _coupon = data.data.institution;
								self.$set(self.userInstitutionList, index, _coupon);
							}
							else{
								self.$toasted.error(data.data.result_msg, {
									position: 'top-center',
								}).goAway(2000)
							}
							
							
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else {
							// alert(data.message)
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
				
			},
			
			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init(() => {
					self.initData();
					self.employee_id = 0;
				})

			},

			//作废准备
			deactivateReady(index) {
				console.log(index);
				let self = this;
			
				let institution = self.userInstitutionList[index];
				console.log(index, institution);
			
				self.selectedIndex = index;
				self.institution = Object.assign({},institution);
			
				$('#cModal').modal('show');
			},
			
			//作废
			deactivate() {
				let self = this;

				console.log('--- self.institution ->', self.institution)
				
				self.disabled = true;
				
				self.loader = self.$loading.show();

				// 组装数据
				let postData = {
					token: self.token,
					// dept_id: self.dept_id,
					// id: self.institution.id,
					// institution_id: self.institution.institution_id,
					// deactivate_reason: self.institution.deactivate_reason || '',
					user_institution_id:self.institution.id,
					nonce_str: self.institution.nonce_str || '',
				}
				
				let postData2 = new FormData();
				for(var key in postData){
					console.log(key+':'+postData[key]);
					postData2.append(key,postData[key]);
				}

				//调用接口
				self.axios.post(self.GLOBAL.baseURI + 'delUserInstitution', 
						postData2
					).then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						
						self.loader.hide();
						
						self.disabled = false;

						if (data.code == 200) {
							
							if(data.data.result_code == 'success'){
								self.userInstitutionList.splice(self.selectedIndex, 1);
								
								self.$toasted.success( '成功作废', {
									position: 'top-center',
									duration: '2000',
								})
								
								$('#cModal').modal('hide');
							}
							else{
								self.$toasted.error( data.data.result_msg, {
									position: 'top-center',
									duration: '2000',
								})
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 404) {
							 self.$router.push({ name: '404'});
							 
							 return;
						}
						else {
							self.$toasted.error( data.message, {
								position: 'top-center',
								duration: '2000',
							})
						}
					});
				//END
			},

			
		},
	}
</script>

<style>
	.mx-icon-calendar,
	.mx-icon-clear {
		right: 28px !important;
	}
</style>
